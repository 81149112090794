import React from 'react';
import styled from 'styled-components';

import VoucherPhoto from '../assets/bonpodarunkowy.jpg';

// Container for the entire voucher section
const VoucherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 150px;
    padding: 10px;
  }
`;

// Styling for the title text
const ToDo = styled.h1`
  font-size: 50px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 35px;
  }
`;

// Content container to wrap the text content
const TextContent = styled.div`
  max-width: 600px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

// Description text with proper line-height for readability
const Description = styled.p`
  font-size: 18px;
  line-height: 1.6;
  color: #555;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Voucher = () => {
  return (
    <VoucherContainer>
      <TextContent>
        <ToDo>Bon Podarunkowy</ToDo>
        <Description>
          Szukasz idealnego prezentu dla bliskiej osoby? Bon podarunkowy to doskonały wybór! Możesz obdarować swoich bliskich voucherem na każdą usługę dostępną na mojej stronie. Niezależnie od okazji, bon podarunkowy będzie wspaniałym gestem i pozwoli obdarowanej osobie wybrać coś, co naprawdę jej się spodoba. Spraw, aby każdy moment stał się wyjątkowy!
        </Description>
      </TextContent>
    </VoucherContainer>
  );
};

export default Voucher;
