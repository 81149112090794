import React, { useState } from 'react';
import styled from 'styled-components';
import { FaSearchPlus } from 'react-icons/fa';

import Face from '../assets/face_photo.jpg';
import Cert1 from '../services/certificates/accessbars_cert.jpg';
import Cert2 from '../services/certificates/ajurweda_cert.jpg';
import Cert3 from '../services/certificates/biotherapy_cert.jpg';
import Cert4 from '../services/certificates/kobido_cert.jpg';
import Cert5 from '../services/certificates/soulplan_cert.jpg';

// Styles using styled-components
const AboutContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-height: 100vh;
  margin-bottom: 100px;
  box-sizing: border-box;
`;

const AboutContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const ProfileImage = styled.img`
  width: 450px;
  height: 850px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: 50px;
  }
`;

const AboutText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 550px;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 15px;
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
  text-align: justify;
  letter-spacing: 0.5px;
  word-spacing: 1.5px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const Highlight = styled.span`
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`;

// Image wrapper to center icon
const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const CertificatesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 20px;
  margin-top: 40px;
  width: 100%;
  max-width: 1000px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
  }
`;

const CertificateImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: fill;
`;

// Zoom Icon styled for overlay
const ZoomIcon = styled(FaSearchPlus)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5rem;
  opacity: 0.5; /* Adjust opacity here */
  pointer-events: none;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  max-width: 90vw;
  max-height: 90vh;
`;

const ZoomedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  background-color: #002244;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0;
`;

const About = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <AboutContainer>
        <AboutContent>
          <ProfileImage src={Face} alt="Profile" />
          <AboutText>
            <Title>Witaj w mojej energetycznej przestrzeni</Title>
            <Description>
              Od kilku lat interesuję się <Highlight>numerologią</Highlight>, <Highlight>astrologią</Highlight>, <Highlight>ajurwedą</Highlight> i naturalnymi terapiami oraz masażami. 
              Ukończyłam wiele kursów masażu twarzy i ciała, terapii naturalnych i rozwoju duchowego.
              <br /><br />
              Znajdziesz tutaj ukojenie na każdym poziomie swoich potrzeb. Jestem certyfikowanym <Highlight>bioenergoterapeutą</Highlight> i wierzę, że dbanie o swoje ciało, emocje, umysł i ducha są ważne na tym samym poziomie, aby móc rozwijać się w pełni swojego potencjału. Wspierając jeden z tych elementów, dbamy również o pozostałe.
              <br /><br />
              Zatroszczę się zarówno o Twoje Ciało, jak i Ducha, w pełni otulając tą sferę, która potrzebuje zaopiekowania najbardziej, przyjemności, karmiącej obie sfery w moich usługach pełen dostatek.
              <br /><br />
              Zaznasz u mnie całą gamę doświadczeń cielesno-duchowych, od pełnego relaksu, ukojenia i rozluźnienia obolałych części; po ujędrnienie, rozruszanie i rozbudzenie.
              <br /><br />
              Jeśli masz ochotę poszerzyć swoje pole świadomości i zadbać o rozwój duchowy; zapraszam Cię również na sesję odczytu Planu Duszy, który pozwoli Ci zrozumieć lepiej siebie, odkryć swoje talenty, cele, wyzwania i misje oraz jakości, które Twoje Dusza sobie wybrała aby doświadczyć w obecnym wcieleniu. Poznasz swoje słabe i mocne strony, dzięki którym będziesz mógł bardziej świadomie pokierować swoim życiem.
            </Description>
          </AboutText>
        </AboutContent>

        {/* Certificates Section */}
        <Title>Moje Certyfikaty</Title>

        <CertificatesGrid>
          <ImageWrapper>
            <CertificateImage src={Cert1} alt="Certificate 1" onClick={() => handleImageClick(Cert1)} />
            <ZoomIcon />
          </ImageWrapper>

          <ImageWrapper>
            <CertificateImage src={Cert2} alt="Certificate 2" onClick={() => handleImageClick(Cert2)} />
            <ZoomIcon />
          </ImageWrapper>

          <ImageWrapper>
            <CertificateImage src={Cert3} alt="Certificate 3" onClick={() => handleImageClick(Cert3)} />
            <ZoomIcon />
          </ImageWrapper>

          <ImageWrapper>
            <CertificateImage src={Cert4} alt="Certificate 4" onClick={() => handleImageClick(Cert4)} />
            <ZoomIcon />
          </ImageWrapper>

          <ImageWrapper>
            <CertificateImage src={Cert5} alt="Certificate 5" onClick={() => handleImageClick(Cert5)} />
            <ZoomIcon />
          </ImageWrapper>
        </CertificatesGrid>

        {selectedImage && (
          <ModalOverlay onClick={closeModal}>
            <ModalContent>
              <ZoomedImage src={selectedImage} alt="Zoomed" />
            </ModalContent>
          </ModalOverlay>
        )}
      </AboutContainer>
    </>
  );
};

export default About;
