import React from 'react';
import styled from 'styled-components';

import AjurwedaPhoto from '../massages/photo/ajurweda.jpg';

// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Adjusted max-width for readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */

  @media (max-width: 1024px) {
    max-width: 80%; /* Expand to 80% for tablet-sized screens */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Expand to 90% for mobile-sized screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Reduce padding on smaller screens */
  }
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 28px; /* Adjusted font size for emphasis */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 24px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 20px; /* Further reduce font size for mobile */
  }
`;

// Section Title with larger font size and bold weight
const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 18px; /* Further reduce font size for mobile */
  }
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;

  li {
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 10px; /* Adjust margin for smaller screens */
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// Image element styled with fixed dimensions
const StyledImage = styled.img`
  width: 900px; /* Set the desired width */
  height: 500px; /* Set the desired height */
  border-radius: 8px;
  margin: 0 auto; /* Margin around the image */
  display: block;

  @media (max-width: 1024px) {
    width: 100%; /* Adjust image width for tablet screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 768px) {
    width: 100%; /* Adjust image width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 480px) {
    width: 100%; /* Adjust image width for mobile */
    height: auto; /* Maintain aspect ratio */
  }
`;
// Main component for the Head Massage article
const Shiroabhyanga = () => {
  return (
    <ArticleContainer>
      <Title>Co to jest masaż Shiroabhyanga?</Title>
      <Paragraph>
        Zgodnie z Ajurwedą głowa to ważny ośrodek energii w ciele. To tu swoją siedzibę ma prana, czyli subtelna energia, która kontroluje i wzmacnia nasze zmysły, inteligencję, a przede wszystkim układ nerwowy. Zakłócenie prawidłowego przepływu prany spowodowanego niezdrowym stylem życia, nieodpowiednią dietą, napięciami i lekami, które nam towarzyszą i są częścią współczesnego życia, powoduje blokady wywołując uczucie napięcia i stresu. Masaż głowy jest zabiegiem, który przywraca równowagę energii w ciele. Doskonale działa na naszą psychikę, uspokaja, relaksuje umysł i ciało. Likwiduje nagromadzone napięcia mentalne i fizyczne. Na naszej głowie znajdują się również liczne punkty witalne (akupresurowe) w ajurwedzie nazywane marmami, stymulując je oddziałujemy na odpowiednie organy wewnętrzne.
      </Paragraph>
      <Paragraph>
        Masowanie głowy jest również zabiegiem pielęgnacyjnym, do którego używa się odpowiednich olejów. Masaż skóry czaszki pobudza krążenie krwi, dzięki temu odżywia i oczyszcza skórę głowy z toksyn, wzmacnia cebulki włosów i stymuluje ich porost zapobiegając przedwczesnemu łysieniu i siwieniu.
      </Paragraph>
      <StyledImage src={AjurwedaPhoto} alt="Head massage" />

      <SectionTitle>Korzyści z masażu głowy</SectionTitle>
      <List>
        <li>Działanie terapeutyczne</li>
        <li>Przywraca równowagę energetyczną organizmu</li>
        <li>Zapewnia głęboki relaks, eliminuje stres i usuwa zmęczenie</li>
        <li>Pomaga w leczeniu bólów głowy, bezsenności, problemów z koncentracją i pamięcią</li>
        <li>Łagodzi napięcia mięśniowe: głowy, szyi i barków</li>
        <li>Odżywia i poprawia kondycję skóry i włosów, pobudza ich porost, opóźnia siwienie i łysienie</li>
        <li>Poprawia wydolność układu krwionośnego</li>
        <li>Dotlenia organizm</li>
        <li>Usuwa toksyny</li>
      </List>
      <SectionTitle>Przeciwwskazania</SectionTitle>
      <List>
        <li>Choroby skóry</li>
        <li>Stany zapalne</li>
        <li>Gorączka</li>
        <li>Nadciśnienie</li>
      </List>
      <Paragraph>
        Czas trwania masażu: 45 minut
      </Paragraph>
    </ArticleContainer>
  );
};

export default Shiroabhyanga;
