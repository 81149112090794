import React from 'react';
import styled from 'styled-components';

// Import images from the local directory
import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import image4 from './images/image4.png';
import image5 from './images/image5.jpeg';
import image6 from './images/image6.jpg';

// Styled components
const GalleryContainer = styled.div`
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
`;

const Title = styled.h1`
    font-size: 48px;
    margin-bottom: 40px;
    color: #333;
`;

const PhotoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Dynamically scale columns */
    gap: 30px;
    justify-items: center;
    max-width: 1000px;
    margin: 0 auto;
`;

const Photo = styled.img`
    width: 100%;
    height: 100%;
    max-width: 300px; /* Limit max width */
    max-height: 300px; /* Limit max height */
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Gallery = () => {
    // Array of imported image objects
    const photos = [image1, image2, image3, image4, image5, image6];

    return (
        <GalleryContainer>
            <Title>Galeria</Title>
            <PhotoGrid>
                {photos.map((photo, index) => (
                    <Photo key={index} src={photo} alt={`Gallery image ${index + 1}`} />
                ))}
            </PhotoGrid>
        </GalleryContainer>
    );
};

export default Gallery;
