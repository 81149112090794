import React from 'react';
import styled from 'styled-components';

import UdvartanaPhoto from '../massages/photo/udvartana.jpg';
// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Adjusted max-width for readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */

  @media (max-width: 1024px) {
    max-width: 80%; /* Expand to 80% for tablet-sized screens */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Expand to 90% for mobile-sized screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Reduce padding on smaller screens */
  }
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 28px; /* Adjusted font size for emphasis */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 24px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 20px; /* Further reduce font size for mobile */
  }
`;

// Section Title with larger font size and bold weight
const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 18px; /* Further reduce font size for mobile */
  }
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;

  li {
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 10px; /* Adjust margin for smaller screens */
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// Image element styled with fixed dimensions
const StyledImage = styled.img`
  width: 900px; /* Set the desired width */
  height: 500px; /* Set the desired height */
  border-radius: 8px;
  margin: 0 auto; /* Margin around the image */
  display: block;

  @media (max-width: 1024px) {
    width: 100%; /* Adjust image width for tablet screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 768px) {
    width: 100%; /* Adjust image width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 480px) {
    width: 100%; /* Adjust image width for mobile */
    height: auto; /* Maintain aspect ratio */
  }
`;

// Main component for the Udvartana article
const Udvartana = () => {
  return (
    <ArticleContainer>
      <Title>Co to jest masaż Udvartana?</Title>
      
      <Paragraph>
        Całkowicie naturalny zabieg wspomagający odchudzanie. Specjalistyczny masaż pudrowy wykonywany unikalną mieszanką ziołową i olejem. Efektywnie wspomaga odchudzanie, doskonały dla skóry tłustej. Całe ciało posypywane jest ziołami i głęboko masowane określonymi ruchami.
      </Paragraph>
      <Paragraph>
        Jest masażem dynamicznym i intensywnym, dającym bardzo ciekawe odczucia na skórze. Przypomina peeling mechaniczny, i rzeczywiście skóra jest po nim gładka, miękka, przyjemna w dotyku, odświeżona. A jednocześnie – ponieważ jest to masaż, a nie zabieg kosmetyczny – czujesz się po niej doskonale zrelaksowana i odprężona. Jest jeszcze jeden ciekawy aspekt tego zabiegu – udvartana bardzo pobudza skórę. A ponieważ o skórze mawia się, że jest naszą drugą wątrobą, możemy uzyskać efekt detoksu, czyli poprzez skórę pozbyć się toksyn z organizmu.
      </Paragraph>
      <Paragraph>
        Ajurweda poleca udvartana przede wszystkim przy kuracjach odchudzających, gdy chcemy walczyć z nadwagą, otyłością, bowiem stymuluje metabolizm. Stosowana jest przy skłonności do zatrzymywania wody w organizmie, do cellulitu, a także gdy jesteśmy ospali, ociężali, mamy tendencje do zbyt długiego snu. Taki zabieg sprawdza się również, gdy walczymy z wysokim cholesterolem lub cukrzycą. Udvartana poprawia także kondycję skóry, zwłaszcza gdy ma skłonność do przetłuszczania. Można ją również stosować przy chorobach skóry typu łuszczyca.
      </Paragraph>
      <StyledImage src={UdvartanaPhoto} alt="Udvartana massage" />

      <SectionTitle>Działanie</SectionTitle>
      <List>
        <li>Masaż ten oczyszcza i odżywia skórę</li>
        <li>Działa tonizująco na mięśnie</li>
        <li>Ma działanie wyszczuplające, dzięki temu, że poprawia metabolizm w mięśniach i tkance tłuszczowej</li>
        <li>Działa bardzo odżywczo i sprawia, że skóra staje się zmiękczona i wygładzona</li>
        <li>Wzmacnia ciało</li>
        <li>Redukuje cellulit</li>
      </List>
      <SectionTitle>Przeciwwskazania</SectionTitle>
      <List>
        <li>Osteoporoza</li>
        <li>Wszelkiego rodzaju stany zapalne, zwłaszcza skóry, wszelkie rany i uszkodzenia skóry, owrzodzenia, wysypki, zapalenie mieszków włosowych, stany ropne</li>
        <li>Choroby krążenia</li>
        <li>Żylaki i pajączki</li>
        <li>Choroby serca</li>
        <li>Kobiety w ciąży, dzieci</li>
      </List>
      <Paragraph>
        Czas trwania masażu: 60 minut
      </Paragraph>
    </ArticleContainer>
  );
};

export default Udvartana;
