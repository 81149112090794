import React from 'react';
import styled from 'styled-components';

import KobidoPhoto from '../massages/photo/kobido.jpg';
// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Adjusted max-width for readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */

  @media (max-width: 1024px) {
    max-width: 80%; /* Expand to 80% for tablet-sized screens */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Expand to 90% for mobile-sized screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Reduce padding on smaller screens */
  }
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 28px; /* Adjusted font size for emphasis */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 24px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 20px; /* Further reduce font size for mobile */
  }
`;

// Section Title with larger font size and bold weight
const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 18px; /* Further reduce font size for mobile */
  }
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;

  li {
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 10px; /* Adjust margin for smaller screens */
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// Image element styled with fixed dimensions
const StyledImage = styled.img`
  width: 900px; /* Set the desired width */
  height: 500px; /* Set the desired height */
  border-radius: 8px;
  margin: 0 auto; /* Margin around the image */
  display: block;

  @media (max-width: 1024px) {
    width: 100%; /* Adjust image width for tablet screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 768px) {
    width: 100%; /* Adjust image width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 480px) {
    width: 100%; /* Adjust image width for mobile */
    height: auto; /* Maintain aspect ratio */
  }
`;
// Main component for the Kobido massage article
const Kobido = () => {
  return (
    <ArticleContainer>
      <Title>Czym jest masaż Kobido?</Title>
      <Paragraph>
        Kobido to niepowtarzalna forma sztuki masażu, która oznacza „starożytną drogę do piękna”. To rytuał, podlegający ściśle określonym regułom, bazujący na tradycji medycyny chińskiej, znanej jako „amma” (w dosłownym tłumaczeniu – naciskanie i pocieranie).
      </Paragraph>
      <Paragraph>
        Kobido to zestaw manualnych technik masażu o różnej intensywności. U jego podstaw leży założenie, że piękno jest odzwierciedleniem ogólnego stanu organizmu: jest wynikiem równowagi zdrowia fizycznego, psychicznego i emocjonalnego. Kobido działa nie tylko na powierzchnię skóry, ale również na jej głębsze warstwy oraz mięśnie. Ma na celu usunąć napięcie, poprawić przepływ krwi i limfy, uaktywnić nerwy, a co za tym idzie zwiększyć przepływ Qi (energii).
      </Paragraph>
      <StyledImage src={KobidoPhoto} alt="Kobido massage" />
      <Paragraph>
        Japoński masaż Kobido ma działanie liftingujące. Co ważne, lifting dotyczy wielu poziomów (skóra, tkanka podskórna, tłuszcz, powięzi, mięśnie). Różnorodność sposobów masowania oraz wiele stopni intensywności masażu umożliwia zmniejszenia napięcia mięśni twarzy. Prowadzi to do zmniejszenia widoczności zmarszczek mimicznych.
      </Paragraph>
      <Paragraph>
        Masaż oddziałuje również na głębiej położone mięśnie twarzy, stymulując je do pracy, w rezultacie poprawia się owal twarzy. Silny ucisk stymuluje produkcję kolagenu oraz elastyny w skórze, co poprawia jej napięcie i jędrność. Ruch drenujący natomiast skutecznie wspiera odprowadzanie z twarzy nadmiaru nagromadzonej limfy. Kobido to również rewelacyjny sposób na opadające powieki.
      </Paragraph>
      <Paragraph>
        Jest całkowicie naturalną i nieinwazyjną alternatywą dla wszystkich, którzy boją się igieł, nie mogą, bądź nie chcą poddać się chirurgicznemu liftingowi twarzy. Jest niezwykle relaksujący i odprężający. Obszar, na którym działamy to mięśnie naramienne, dekoltu, szyjne, grzbietu, głowy oraz twarzy.
      </Paragraph>
      <Highlight>
        Efekty masażu Kobido
      </Highlight>
      <List>
        <li>Wygładzenie zmarszczek, fałd i bruzd na skórze</li>
        <li>Ujędrnienie oraz lifting skóry</li>
        <li>Odprężenie mięśni twarzy, szyi, dekoltu i karku</li>
        <li>Zmniejszenie worków oraz obrzęków pod oczami</li>
        <li>Odzyskanie utraconego blasku skóry</li>
        <li>Poprawa owalu twarzy</li>
        <li>Usunięcie toksyn</li>
        <li>Wzmocnienie i przyrost tkanki mięśniowej</li>
      </List>
      <Paragraph>
        Zmiany w wyglądzie twarzy są widoczne już po pierwszym zabiegu. Zmieniają się rysy twarzy, skóra staje się bardziej promienna i widocznie odmłodzona.
      </Paragraph>
      <Highlight>
        Przeciwskazania
      </Highlight>
      <List>
        <li>Ostre stany zapalne organizmu, skóry</li>
        <li>Ekstrakcje zębowe, stany ropne okołozębowe</li>
        <li>Do 4 tygodni po zastosowaniu wypełniaczy, botoxu</li>
        <li>Nici PDO – do 6 miesięcy</li>
        <li>Po zabiegach laserowym oraz mezoterapii zaraz po zaniku stanu zapalnego</li>
      </List>
      <Paragraph>
        Czas trwania sesji: 60 minut
      </Paragraph>
    </ArticleContainer>
  );
};

export default Kobido;
