import React from 'react';
import styled from 'styled-components';

import RatnaabhyangaPhoto from '../massages/photo/ratnaabhyanga.jpg';
// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Adjusted max-width for readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */

  @media (max-width: 1024px) {
    max-width: 80%; /* Expand to 80% for tablet-sized screens */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Expand to 90% for mobile-sized screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Reduce padding on smaller screens */
  }
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 28px; /* Adjusted font size for emphasis */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 24px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 20px; /* Further reduce font size for mobile */
  }
`;

// Section Title with larger font size and bold weight
const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 18px; /* Further reduce font size for mobile */
  }
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;

  li {
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 10px; /* Adjust margin for smaller screens */
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// Image element styled with fixed dimensions
const StyledImage = styled.img`
  width: 900px; /* Set the desired width */
  height: 500px; /* Set the desired height */
  border-radius: 8px;
  margin: 0 auto; /* Margin around the image */
  display: block;

  @media (max-width: 1024px) {
    width: 100%; /* Adjust image width for tablet screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 768px) {
    width: 100%; /* Adjust image width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 480px) {
    width: 100%; /* Adjust image width for mobile */
    height: auto; /* Maintain aspect ratio */
  }
`;
// Main component for the Ratnaabhyanga article
const Ratnaabhyanga = () => {
  return (
    <ArticleContainer>
      <Title>Co to jest masaż Ratnaabhyanga?</Title>
      <Paragraph>
        Ratna w sanskrycie znaczy „klejnot, kamień szlachetny”, a z kolei bianga oznacza „masaż, namaszczenie”. Połączenie tych dwóch elementów to Ratnaabhyanga. Jest to masaż dużymi gorącymi kamieniami półszlachetnymi i ciepłym 100% naturalnym certyfikowanym olejem.
      </Paragraph>
      <Paragraph>
        Co ważne, masaż gorącymi kamieniami półszlachetnymi polega przede wszystkim na masowaniu, a nie tylko na układaniu kamieni na ciele klienta. Podczas masażu używa się kamieni unikatów i kryształów, obrabianych tylko na specjalne zamówienie z surowców pochodzących z różnych regionów Indii. Są to przede wszystkim japisy, kareneole, awenturyn, kryształ górski, tygrysie oko, ametyst, kwarc różowy. Każdy z kamieni ma inne właściwości i określony wpływ na ciało.
      </Paragraph>
      <StyledImage src={RatnaabhyangaPhoto} alt="Ratnaabhyanga massage" />

      <Paragraph>
        Kamienie półszlachetne z racji swoich znakomitych właściwości regenerujących wywierają dobroczynny wpływ na cały organizm. Jest to wyjątkowo ekskluzywny masaż, zarówno ze względu na przyjemne doznania, jak i sam wygląd kamieni. Posiada on działanie witalizujące i doskonale likwiduje napięcia i bóle mięśni. Dodaje energii każdemu rodzajowi skóry, wpływa na witalność organizmu, przynosi ukojenie mięśniom i neutralizuje stres.
      </Paragraph>
      <Paragraph>
        Masaż Ratnaabhyanga opiera się na teorii przepływu energii w ciele. W organizmie znajdują się ośrodki energii tzw. czakry. Podczas masażu kamienie układane są na ciele zgodnie z mapą punktów energetycznych, następnie masując przesuwa się je wzdłuż kanałów łączących czakry – zwanych meridianami. Zabieg ten skupia się głównie na masowaniu ciała, nie ogranicza się tylko do układania i przesuwania wzdłuż ciała kamieni. Podczas masażu równoważone są centra energetyczne oraz stymulowany jest system nerwowy organizmu. Przepływ energii pozwala na szybszą regenerację organizmu. Efekt ten może zostać wzmocniony dzięki użyciu do masażu naturalnych olejków.
      </Paragraph>
      <SectionTitle>Wskazania do masażu Ratnaabhyanga</SectionTitle>
      <List>
        <li>Pozytywny wpływ na układ nerwowy, limfatyczny, krążenia a także na tkankę mięśniową oraz skórę</li>
        <li>Złagodzenie, a nawet ustąpienie bóli mięśniowych</li>
        <li>Zmniejszenie obrzęków</li>
        <li>Poprawa wyglądu skóry – masaż zwiększa jędrność i elastyczność skóry</li>
        <li>Ma działanie fizjoterapeutyczne</li>
        <li>Wspiera regenerację organizmu, a w okresie rekonwalescencji pomaga przywrócić sprawność</li>
        <li>Łagodzi objawy i skutki stresu</li>
      </List>
      <SectionTitle>Przeciwwskazania</SectionTitle>
      <List>
        <li>Gorączka</li>
        <li>Zaburzenia czucia</li>
        <li>Ostre stany zapalne</li>
        <li>Zakrzepy i zapalenia żył</li>
        <li>Zmiany dermatologiczne takie jak owrzodzenia, wypryski, stany ropne</li>
        <li>Ciąża</li>
        <li>Choroby nowotworowe oraz łamliwość kości</li>
      </List>
      <Paragraph>
        Czas trwania masażu: 75 minut
      </Paragraph>
    </ArticleContainer>
  );
};

export default Ratnaabhyanga;
