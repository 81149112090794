import React from 'react';
import styled from 'styled-components';

// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Increased max-width for better readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 26px; /* Adjusted font size */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

// Subtitle or introduction with a light shadow for contrast
const Subtitle = styled.h2`
  font-size: 20px; /* Adjusted font size */
  margin-bottom: 15px;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;
  li {
    margin-bottom: 10px;
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;
`;

// Image element styled
const StyledImage = styled.img`
  width: 100%; /* Ensures image fits within the container width */
  height: auto;
  border-radius: 8px;
  margin: 20px 0; /* Margin around the image */
`;

// Main component for the SoulPlan article
const SoulPlan = () => {
  return (
    <ArticleContainer>
      <Title>Czym jest Odczyt Planu Duszy?</Title>
      <Paragraph>
        System Planu Duszy w obecnej formie powstał na bazie przekazu Franka Alpera i w opracowaniu Blue Marsdena, twórcy szkoły terapii alternatywnych Holistic Healing College z siedzibą w Londynie UK.
      </Paragraph>
      <Paragraph>
        Plan Duszy umożliwia lepsze zrozumienie naszej obecnej inkarnacji, pomaga obudzić i uaktywnić własny utajony potencjał, a także może stać się narzędziem pomocy dla innych.
      </Paragraph>
      <Paragraph>
        System Odczytywania Planu Duszy ma swoje korzenie w starożytnej Hebrajskiej Gematrii, która kojarzy się powszechnie z numerologią hebrajską. Jest nową interpretacją starożytnego systemu analizy celu życia. Różnica pomiędzy zwykłą numerologią, a Planem Duszy polega na tym, że bazą w numerologii tradycyjnej jest dziewięć energii, dziewięć wibracji, a w Planie Duszy mamy dwadzieścia dwie wibracje, które pochodzą z alfabetu hebrajskiego, zawierającego właśnie dwadzieścia dwie litery.
      </Paragraph>
      <Paragraph>
        Wibracja twoich imion i nazwiska rodowego nadanych przy urodzeniu determinuje twój cały Plan Duszy. Zapis tych energii opiera się na bazie gwiazdy stworzenia, wyglądającej jak dwa trójkąty. Jeden z nich jest skierowany w dół i opisuje aspekty ziemskie, drugi, skierowany w górę, opisuje aspekty duchowe.
      </Paragraph>
      <Paragraph>
        Możemy jasno zobaczyć swoje największe wyzwania i odkryć w jaki sposób je pokonać, odkryć swoje mocne strony (karierę, kreatywność, finansowe i duchowe talenty) i dostroić się do swojego wyższego Celu Duszy, zrozumieć przeszłość i ujawnić potencjał swojej najlepszej przyszłości, otrzymać energetyczną aktywację i praktyczne narzędzia do uzdrawiania i dostrajania się do swojego celu.
      </Paragraph>
      <Highlight>
        Dzięki Odczytowi Planu Twojej Duszy:
      </Highlight>
      <List>
        <li>Zrozumiesz po co przyszłaś/przyszedłeś na ten świat, ponieważ poznasz swój własny Plan Duszy, dzięki czemu Twoje życie nabierze głębokiego sensu i zrozumienia.</li>
        <li>Jasno zrozumiesz swoje największe wyzwania w tym życiu i zrozumiesz, w jaki sposób sobie z nimi poradzić, dzięki czemu będziesz miał/a konkretne wytyczne na swoje życie.</li>
        <li>Odkryjesz swoje mocne strony – swoje talenty w różnych dziedzinach życia – kariera, kreatywność, finanse i duchowe talenty.</li>
        <li>Zrozumiesz swoją przeszłość i poznasz potencjał swojej najlepszej przyszłości.</li>
        <li>Zinterpretujesz i zrozumiesz wibracje swojego imienia.</li>
      </List>
      <Highlight>
        Co zyskasz po Sesji Odczytu Planu Duszy?
      </Highlight>
      <List>
        <li>Większe i jaśniejsze poczucie celu życia, kontekst do zrozumienia, dlaczego doświadczyłeś/aś życia, której do tej pory miałeś/aś, głębiej zrozumiesz dotychczasowe doświadczenia życiowe.</li>
        <li>Dostęp do siebie na głębszym poziomie – na poziomie Duszy.</li>
        <li>Więcej jasności co do życia i swojej roli w nim.</li>
        <li>Uzdrowienie i oczyszczenie zbędnych wzorów.</li>
        <li>Głęboką aktywację własnych unikalnych zdolności i potencjału.</li>
      </List>
      <Paragraph>
        Będziesz w stanie pozytywnie i ze zrozumieniem spojrzeć na swoje przeszłe doświadczenia i na teraźniejszość. Czyż to nie wspaniale uczucie móc maksymalnie wykorzystać swoje talenty, skupić się na swoich mocnych stronach i pokonać słabości?
      </Paragraph>
      <Paragraph>
        Sesja Planu Duszy pozwoli ci potwierdzić, dlaczego twoje życie toczy się w taki, a nie inny sposób i dlaczego doświadczasz określonych emocji. To pozwoli ci uzdrowić twoje relacje z innymi ludźmi, a także doświadczysz wybudzenia się z iluzji widzenia świata z jednej tylko perspektywy. Popatrzysz na swoje relacje, związki i sytuacje inaczej i twój rozwój osobisty gwałtownie przyspieszy.
      </Paragraph>
      <Paragraph>
        Sesja da ci jasny kierunek w stronę którego będziesz zmierzać pewniej niż dotychczas. Odczyt Planu Duszy da ci wgląd w przyszłe twoje życie i zobaczysz nowe możliwości i będziesz wiedzieć jak wykorzystać swoje talenty by osiągnąć sukces życiowy.
      </Paragraph>
      <Paragraph>
        Odczyt zawiera godzinną sesję, w trakcie której omawiamy wcześniej przygotowany Plan Duszy oraz medytację uzdrawiającą. Po zakończonej sesji otrzymasz osobisty Plan Duszy – opis zawierający: Ziemskie Wyzwania, Ziemskie Talenty, Cele Ziemskie, Wyzwania Duchowe, Cele Duchowe, Talenty Duchowe i Przekaz od Twojej Duszy.
      </Paragraph>
      <Paragraph>
        Czas trwania sesji: 2 godziny
      </Paragraph>
    </ArticleContainer>
  );
};

export default SoulPlan;
