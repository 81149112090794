import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram } from 'react-icons/fa'; // Importing icons from react-icons

const OuterFooter = styled.footer`
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(20, 9, 121, 1) 0%, rgba(8, 22, 133, 1) 9%, rgba(7, 46, 155, 1) 25%, rgba(6, 61, 168, 1) 35%, rgba(4, 93, 196, 1) 56%, rgba(2, 123, 219, 1) 73%, rgba(0, 159, 255, 1) 100%);
  padding: 30px;
  display: flex;  /* Use flexbox for layout */
  justify-content: space-between;  /* Space out items between left and right */
  align-items: center;  /* Vertically center items */
  color: white;  /* Ensure the text color is readable */
`;

const LeftSection = styled.div`
  font-size: 16px;
  margin-left: 20%;
`;

const RightSection = styled.div`
  margin-right: 20%;

  display: flex;
  gap: 15px;  /* Add space between icons */
  
  a {
    color: white;
    font-size: 24px;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const Footer = () => {
  return (
    <OuterFooter>
      <LeftSection>2024 © Body & Soul Balance</LeftSection>
      <RightSection>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebook />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>
      </RightSection>
    </OuterFooter>
  );
};

export default Footer;
