import React from 'react';
import styled from 'styled-components';


// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Increased max-width for better readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 28px; /* Adjusted font size for emphasis */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

// Subtitle or introduction with a light shadow for contrast
const Subtitle = styled.h2`
  font-size: 22px; /* Adjusted font size for visibility */
  margin-bottom: 15px;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;
  li {
    margin-bottom: 10px;
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;
`;

// Image element styled
const StyledImage = styled.img`
  width: 60%; /* Ensures image fits within the container width */
  height: 600px;
  border-radius: 8px;
  margin: 0 auto;
  `;

// Main component for the Ear Candling article
const Ears = () => {
  return (
    <ArticleContainer>
      <Title>Czym jest świecowanie (konchowanie) uszu?</Title>
      <Paragraph>
        Konchowanie uszu, inaczej świecowanie, to popularny zabieg medycyny alternatywnej, którego celem jest usunięcie zanieczyszczeń z ucha, a także wyleczenie niektórych schorzeń. Świecowanie uszu było już znane w starożytnym Egipcie, Chinach, Indiach, Tybecie, jak również w kulturach Azteków i Majów.
      </Paragraph>
      <Paragraph>
        Podczas spalania się świecy wytwarzane jest podciśnienie w kanale usznym na skutek tzw. efektu komina. Prowadzi to do dostrzegalnej regulacji ciśnienia zatok. Ciśnienie krążące w kanale świecy dostarcza stężoną parę wzbogaconą ziołowymi olejkami eterycznymi do wewnątrz ucha. W wyniku tego następuje leczenie podrażnionych stref oraz usuwanie z ucha zanieczyszczeń i osadów na skutek działania prądu podciśnienia.
      </Paragraph>
      <Paragraph>
        Podczas procesu spalania nagrzewająca się obudowa ze srebrnej blaszki przenosi łagodny uspokajający prąd ciepła i olejków ziołowych do wnętrza ucha. Lokalnie dostarczone ciepło powoduje stymulację obwodowego ciśnienia krwi, które wzmacnia immunologiczny system obronny danego miejsca oraz zwiększa częstość krążenia limfatycznego. Ciepło stymuluje punkty energetyczne ucha i powoduje prawidłowy obieg energii. Ponad 90% osób odczuwa w tym czasie obniżenie ciśnienia, lekkość odczuwaną w okolicach uszu i w głowie jako główny efekt świecowania.
      </Paragraph>
      <Highlight>
        Wskazania do zabiegu świecowania uszu:
      </Highlight>
      <List>
        <li>Delikatne oczyszczanie uszu z nadmiaru woskowiny</li>
        <li>Podrażnienie uszu lub zatok</li>
        <li>Zaburzenia ostrości słuchu, niedosłuch</li>
        <li>Błędnikowe zaburzenia równowagi</li>
        <li>Szumiące dzwonienie w uszach</li>
        <li>Regulacja ciśnienia przy bólach głowy, migrenach</li>
        <li>Zapalenie zatok (nosowych, czołowych, szczękowych)</li>
        <li>Nieżyty nosa (również alergiczny nieżyt nosa)</li>
        <li>Zakłócenia krążenia w uchu</li>
        <li>Bóle głowy, migreny</li>
        <li>Rozładowanie stresu i wzmożonego napięcia mięśniowego</li>
        <li>Problemy z czystością widzenia i postrzegania kolorów, zmysłem smaku oraz powonienia</li>
        <li>Kłopoty z zasypianiem</li>
      </List>
      <Highlight>
        Świecowanie uszu – przeciwwskazania
      </Highlight>
      <List>
        <li>Alergia na produkty pszczelarskie</li>
        <li>Stan zapalny, infekcja ucha</li>
        <li>Uszkodzenie błony bębenkowej</li>
        <li>Uraz głowy</li>
        <li>Choroba zakaźna</li>
        <li>Podwyższona temperatura</li>
      </List>
      <Paragraph>
        Czas trwania zabiegu: 20-30 min.
      </Paragraph>
    </ArticleContainer>
  );
};

export default Ears;
