import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link instead of NavLink
import { FaPhone, FaEnvelope, FaClock, FaFacebook, FaInstagram, FaChevronDown } from 'react-icons/fa';
import bslogo from '../assets/bslogo.png';

// Styled container for the outer navigation wrapper
const OuterNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  width: 100%;
  position: relative;
`;

// Top Navigation: for social media links
const TopNav = styled.div`
  flex: 0.2;
  background-color: white;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  gap: 15px;

  a {
    color: black;
    font-size: 24px;
    transition: color 0.2s ease;

    &:hover {
      color: #666;
    }
  }
`;

// Mid Navigation: for logo and contact info
const MidNav = styled.div`
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
  width: 100%;
  color: white;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (min-width: 1400px) {
    flex-direction: row;
    gap: 40px;
  }
`;

// Logo Container
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;

  @media (min-width: 1400px) {
    width: 19%;
    justify-content: flex-start;
  }
`;

// Style for the logo image
const Logo = styled.img`
  height: 100px;
  width: auto;
`;

// Wrapper for the content to ensure proper spacing
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;

  @media (min-width: 1400px) {
    text-align: left;
  }
`;

// Heading Container for the logo's heading text
const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// Heading styles
const Heading = styled.h1`
  font-size: 24px;
  margin: 0;
`;

// Sub-heading styles
const SubHeading = styled.p`
  font-size: 18px;
  margin: 0;
`;

// Wrapper for the contact items
const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (min-width: 1400px) {
    flex-direction: row;
    align-items: center;
  }
`;

// Individual contact item styled to have an icon and two lines of text
const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: center;

  @media (min-width: 1400px) {
    text-align: left;
  }
`;

// Style for the contact icons
const ContactIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 50%;
  font-size: 24px;
`;

// Wrapper for the text inside each contact item
const ContactTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// Name of the contact (e.g., "Phone", "Email")
const ContactName = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: bold;
`;

// Contact details (e.g., actual phone number or email)
const ContactDetails = styled.p`
  font-size: 16px;
  margin: 0;
`;

// Bottom Navigation: for main menu items
const BottomNav = styled.div`
  position: absolute;
  bottom: -50px;
  width: 100%;
  max-width: 800px;
  height: 35%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-top: 2px solid #ccc;
  border-right: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-bottom: none;
  z-index: 1;
  box-sizing: border-box;

  @media (max-width: 1400px) {
    width: 100%;
    height: 50px;
    margin: -20px;
  }

  @media (max-width: 768px) {
    bottom: -100px;
    font-size: 12px;
    width: 100%;
    height: 100px;
  }
`;

// Menu styles for the main navigation links
const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  height: 100%;
  flex: 1;
  padding: 0;
  display: flex;
  gap: 0;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
`;

// Menu Link style
const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
`;

const DropdownLink = styled(Link)`
  text-decoration: none;
  color: inherit;

`;

// Menu Item style for each individual link
const MenuItem = styled.li`
  flex: 1;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-right: 1px solid #ccc;
  position: relative;
  padding: 0 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
  text-align: left;

  &:last-child {
    border-right: none;
  }

  &:hover {
    background: transparent;
  }

  &:hover::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px; /* Adjust thickness of the underline */
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
    transform: scaleX(1);
    transform-origin: bottom left;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
  }

  /* Ensure the MenuLink doesn't interfere with the underline effect */
  ${MenuLink} {
    position: relative;
    z-index: 2;
  }
`;

// Dropdown arrow styles
const DropdownArrow = styled(FaChevronDown)`
  margin-left: 10px;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => isOpen ? 'rotate(360deg)' : 'rotate(270deg)'};
`;

// Dropdown menu styles
const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  list-style: none;
  padding: 10px;
  margin: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  min-width: 200px; /* Adjust based on your needs */
  z-index: 2; /* Ensure it appears above other elements */
  @media (max-width: 768px) {
    min-width: 100px; /* Adjust based on your needs */
    left: -60px;

  }
  li {
    padding: 8px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align text to the left */
    font-size: 16px; /* Adjust font size */
    position: relative;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  /* Nested Dropdown for Masaże */
  li:hover > ul {
    display: block;
    position: absolute;
    top: 0;
    left: 100%; /* Position to the right of the parent item */
  }
`;

// Nested dropdown for Masaże
const NestedDropdownMenu = styled.ul`
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  list-style: none;
  padding: 10px;
  margin: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: none; /* Hidden by default */
  min-width: 150px; /* Adjust based on your needs */
  z-index: 3; /* Ensure it appears above other elements */
`;

// Main component for the navigation bar
const Navbar = () => {
  const [uslugiOpen, setUslugiOpen] = useState(false);
  const [nestedOpen, setNestedOpen] = useState(false);

  return (
    <OuterNav>
      <TopNav>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebook />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>
      </TopNav>

      <MidNav>
        <LogoContainer>
          <Logo src={bslogo} alt="Body & Soul Balance Logo" />
          <HeadingContainer>
            <Heading>Body & Soul Balance</Heading>
            <SubHeading>Salon dla Ciała i Duszy</SubHeading>
          </HeadingContainer>
        </LogoContainer>

        <InfoWrapper>
          <ContactWrapper>
            <ContactItem>
              <ContactIcon>
                <FaPhone />
              </ContactIcon>
              <ContactTextWrapper>
                <ContactName>Numer kontaktowy</ContactName>
                <ContactDetails>789 360 003</ContactDetails>
              </ContactTextWrapper>
            </ContactItem>

            <ContactItem>
              <ContactIcon>
                <FaEnvelope />
              </ContactIcon>
              <ContactTextWrapper>
                <ContactName>Adres email</ContactName>
                <ContactDetails>kontakt@balansduszy.pl</ContactDetails>
              </ContactTextWrapper>
            </ContactItem>

            <ContactItem>
              <ContactIcon>
                <FaClock />
              </ContactIcon>
              <ContactTextWrapper>
                <ContactName>Godziny otwarcia</ContactName>
                <ContactDetails>Pon-Pt: 10:00-20:00</ContactDetails>
              </ContactTextWrapper>
            </ContactItem>
          </ContactWrapper>
        </InfoWrapper>
      </MidNav>

      <BottomNav>
        <Menu>
          <MenuItem>
            <MenuLink to="/">Home</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/about">O mnie</MenuLink>
          </MenuItem>
          <MenuItem
            onMouseEnter={() => setUslugiOpen(true)}
            onMouseLeave={() => setUslugiOpen(false)}
          >
            <MenuLink>
              Usługi
              <DropdownArrow isOpen={uslugiOpen} />
            </MenuLink>
            <DropdownMenu isOpen={uslugiOpen}>
              <li
                onMouseEnter={() => setNestedOpen(true)}
                onMouseLeave={() => setNestedOpen(false)}
              >
                Masaże
                <DropdownArrow isOpen={nestedOpen} />
                <NestedDropdownMenu>
                <DropdownLink to="/kobido"><li>Masaż Kobido</li></DropdownLink>
                <DropdownLink to="/abhyanga"><li>Masaż Abhyanga</li></DropdownLink>
                <DropdownLink to="/shiroabhyanga"><li>Masaż Shiroabhyanga</li></DropdownLink>
                <DropdownLink to="/ratnaabhyanga"><li>Masaż Ratnaabhyanga</li></DropdownLink>
                <DropdownLink to="/udvartana"><li>Masaż Udvartana</li></DropdownLink>
                </NestedDropdownMenu>
              </li>
              <DropdownLink to="/sp"><li>Sesje Planu Duszy</li></DropdownLink>
              <DropdownLink to="/ears"><li>Świecowanie Uszu</li></DropdownLink>
              <DropdownLink to="/accessbars"><li>Access Bars</li></DropdownLink>
              <DropdownLink to="/biotherapy"><li>Sesje Bioterapii</li></DropdownLink>
            </DropdownMenu>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/prices">Cennik</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/contact">Kontakt</MenuLink>
          </MenuItem>
        </Menu>
      </BottomNav>
    </OuterNav>
  );
};

export default Navbar;
