import React from 'react';
import styled from 'styled-components';

import AccessBarsImg from './massages//photo/acces_bars.jpg'; // Importing the Access Bars certificate image

// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Increased max-width for better readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */

  @media (max-width: 768px) {
    max-width: 90%; /* Expand to wider width on smaller screens */
  }
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 26px; /* Adjusted font size */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

// Subtitle or introduction with a light shadow for contrast
const Subtitle = styled.h2`
  font-size: 20px; /* Adjusted font size */
  margin-bottom: 15px;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;
  li {
    margin-bottom: 10px;
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;
`;

// Image element styled with scaling for responsiveness
const StyledImage = styled.img`
  width: 50%; /* Ensures image fits within the container width */
  height: auto;
  border-radius: 8px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%; /* Increase image width on smaller screens */
  }
  @media (max-width: 768px) {
    width: 100%; /* Increase image width on smaller screens */
  }

  @media (max-width: 480px) {
    width: 100%; /* Further increase image width on mobile devices */
  }
`;

// Main component for the Access Bars article
const AccessBars = () => {
  return (
    <ArticleContainer>
      <Title>Terapia Manualna dla Uwalniania Potencjału</Title>
      
      <Paragraph>
        Access Bars to rodzaj terapii manualnej opracowanej ponad 20 lat temu przez Gary’ego Daglasa. Jest to zabieg, który uwalnia od traum i nagromadzonych przekonań, otwierając nowe możliwości. Przez całe życie gromadzimy wzorce, myśli, emocje, idee, oraz przekonania na temat siebie i świata, które często negatywnie wpływają na nasze działania. Terapia Access Bars pozwala oczyścić umysł z niepotrzebnych wzorców i umożliwia wprowadzenie pozytywnych zmian.
      </Paragraph>
      <StyledImage src={AccessBarsImg} alt="Access Bars Certificate" /> {/* Using the imported image */}

      <Highlight>Jak wygląda terapia Access Bars?</Highlight>
      <Paragraph>
        Access Bars to zabieg, który polega na delikatnym, manualnym stymulowaniu 32 punktów Bars na głowie. Odpowiedni dotyk pomaga rozładować spolaryzowany ładunek elektryczny zgromadzony w mózgu, co umożliwia swobodny przepływ energii. 
        Sesja jest delikatna, nieinwazyjna i głęboko relaksująca. Rezultatem terapii jest uwolnienie energii, co daje poczucie spokoju, otwiera nowe możliwości i przestrzeń życiową. 
        Zmiany wprowadzone podczas sesji są trwałe, a terapia może być łączona z innymi technikami pracy z ciałem.
      </Paragraph>
      <Highlight>Na jakie dolegliwości pomaga terapia Access Bars?</Highlight>
      <List>
        <li>Przewlekły stres, depresja, ADHD, spektrum autyzmu</li>
        <li>Problemy emocjonalne, trudności w podejmowaniu decyzji</li>
        <li>Problemy ze snem, lęki, ataki paniki</li>
        <li>Poprawa samooceny i motywacji</li>
        <li>Zwiększona efektywność działania, kreatywność, radość z życia</li>
        <li>Lepsza komunikacja z innymi, łatwiejsze dokonywanie wyborów</li>
      </List>
      <Highlight>U kogo stosować terapię Access Bars?</Highlight>
      <Paragraph>
        Terapię Access Bars można stosować zarówno u dorosłych, jak i dzieci. Sesje trwają zwykle od 60 do 90 minut, jednak w przypadku dzieci są krótsze – od 30 do 45 minut, a u najmłodszych nawet kilkanaście minut.
      </Paragraph>
      <Highlight>Przebieg sesji Access Bars i jak się przygotować?</Highlight>
      <Paragraph>
        Podczas sesji osoba pozostaje w ubraniu, leży na łóżku do masażu, przykryta kocykiem. Terapeuta delikatnie dotyka określonych punktów na głowie. W trakcie sesji fale mózgowe przechodzą ze stanu czuwania (Beta) do stanów Alfa i Theta, podobnych do tych, które osiąga się podczas medytacji. Zabieg jest przyjemny, relaksujący, a jego efekty są trwałe.
      </Paragraph>
    </ArticleContainer>
  );
};

export default AccessBars;
