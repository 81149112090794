import React from 'react';
import styled from 'styled-components';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock } from 'react-icons/fa';

// Styled container for the content on the page
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

`;

const MainContent = styled.div`
  flex: 1; /* Allow this section to grow and take available space */
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

// Google Maps Container
const MapContainer = styled.div`
  width: 50%;
  height: 500px;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

// Contact Info Wrapper
const ContactInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
  border: 4px solid transparent;
  border-image: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
  border-image-slice: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 10px;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

// Heading
const Heading = styled.h2`
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  color: #333;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
    border-radius: 5px;
  }
`;

// Contact Item and Layout
const ContactItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 10px 0;
  color: #333;
`;

const Icon = styled.div`
  margin-right: 10px;
  font-size: 24px;
  color: #333;
`;

const ContactTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left; /* Align text to left */
`;

const ContactName = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: bold;
`;

const ContactDetails = styled.p`
  font-size: 16px;
  margin: 0;
`;

// Wrapper for contact details and opening hours
const ContactDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Separate contact and opening hours
const OpeningHoursWrapper = styled.div`
  margin-top: 40px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: left;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const Contact = () => {
  return (
    <ContactContainer>
      <MainContent>
        {/* Google Maps Embed with a marker */}
        <MapContainer>
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2326.671538449884!2d18.612461319096983!3d54.32743793925378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd73f10a05f1f7%3A0xb6156f916d53ac2c!2sGenera%C5%82a%20Augusta%20Emila%20Fieldorfa%208%2C%2080-041%20Gda%C5%84sk!5e0!3m2!1spl!2spl!4v1726339484082!5m2!1spl!2spl"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </MapContainer>

        {/* Contact Info */}
        <ContactInfo>
          <Heading>Kontakt</Heading>
          <ContactDetailsWrapper>
            <ContactItem>
              <Icon><FaPhone /></Icon>
              <ContactTextWrapper>
                <ContactName>Numer kontaktowy</ContactName>
                <ContactDetails>789 360 003</ContactDetails>
              </ContactTextWrapper>
            </ContactItem>

            <ContactItem>
              <Icon><FaEnvelope /></Icon>
              <ContactTextWrapper>
                <ContactName>Email kontaktowy</ContactName>
                <ContactDetails>bsb.gdansk@gmail.com</ContactDetails>
              </ContactTextWrapper>
            </ContactItem>

            <ContactItem>
              <Icon><FaMapMarkerAlt /></Icon>
              <ContactTextWrapper>
                <ContactName>Adres</ContactName>
                <ContactDetails>ul. Gen. Augusta Emila Fieldorfa 8/2, 80-041 Gdańsk</ContactDetails>
              </ContactTextWrapper>
            </ContactItem>
          </ContactDetailsWrapper>

          {/* Separate Opening Hours */}
          <OpeningHoursWrapper>
            <ContactItem>
              <Icon><FaClock /></Icon>
              <ContactTextWrapper>
                <ContactName>Godziny otwarcia</ContactName>
                <ContactDetails>
                  Poniedziałek - Piątek : 10 - 20<br />
                  Sobota - Niedziela : Możliwość umówienia się po wcześniejszym kontakcie telefonicznym
                </ContactDetails>
              </ContactTextWrapper>
            </ContactItem>
          </OpeningHoursWrapper>
        </ContactInfo>
      </MainContent>
    </ContactContainer>
  );
};

export default Contact;
