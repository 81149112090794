import React from 'react';
import styled from 'styled-components';

// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Increased max-width for better readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */

  @media (max-width: 768px) {
    max-width: 90%; /* Expand to wider width on smaller screens */
  }
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 26px; /* Adjusted font size */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

// Subtitle or introduction with a light shadow for contrast
const Subtitle = styled.h2`
  font-size: 20px; /* Adjusted font size */
  margin-bottom: 15px;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;
  li {
    margin-bottom: 10px;
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;
`;

// Main component for the Biotherapy article
const Biotherapy = () => {
  return (
    <ArticleContainer>
      <Title>Uzdrawianie przez Energię</Title>
      
      <Paragraph>
        Bioenergoterapia jest nieinwazyjnym sposobem uzdrawiania organizmu z większości chorób
        przewlekłych, z określonych stanów nagłych, niwelowania bólu oraz poprawiania komfortu i jakości życia.
        Polega ona na rozprowadzaniu Energii (fal) po ciele pacjenta stymulując układ odpornościowy do
        wyzdrowienia. Współpracuje z medycyną konwencjonalną.
      </Paragraph>

      <Highlight>Jak działa metoda</Highlight>
      <Paragraph>
        Istotą terapii jest przenoszenie Energii z przestrzeni na uczestnika. Energia ta, mówiąc obrazowo, działa
        jak magnes gdzie, poprzez ręce bioenergoterapeuty, rozprowadzana jest po ciele w specjalny sposób.
        Terapeuta dzięki zastosowanym technikom otwiera biopole klienta i ułatwia przepływ tej energii tam gdzie
        ona jest najbardziej potrzebna. Jednym słowem terapeuta uruchamia lub uaktywnia system
        odpornościowy człowieka, który to system /zwany też immunologicznym/ sam przejmuje funkcję
        leczenia. Zabieg ten wyrównuje ładunki bioelektryczne, usuwa blokady energetyczne wzmacniając układ
        immunologiczny, aby ten sam zwalczył chorobę.
        Terapia jest całkowicie bezpieczna i przynosi rzeczywistą ulgę i poprawę zdrowia, co w wielu
        przypadkach widoczne jest niemal natychmiast.
      </Paragraph>

      <Highlight>Jak często stosować terapię?</Highlight>
      <Paragraph>
        Z reguły zabiegi wykonuje się przez 4-5 dni codziennie, a w przypadkach ciężkich stanów chorobowych
        dwukrotnie w dzień. Taki cykl zabiegów powtarza się kilkakrotnie w zależności od stanu zaawansowania
        choroby. W najlżejszych przypadkach do usunięcia bólu lub innej dolegliwości wystarczająca bywa
        jednorazowa terapia.
      </Paragraph>

      <Highlight>Gdzie można stosować terapię?</Highlight>
      <Paragraph>
        Tarapię można wykonywać stacjonarnie lub na odległość (potrzebne jest zdjęcie chorego).
      </Paragraph>
    </ArticleContainer>
  );
};

export default Biotherapy;
