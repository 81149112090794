import React from 'react';
import styled from 'styled-components';

import AbhyangaPhoto from '../massages/photo/abhyanga.jpg';
// Container for the article with a gradient background and image
const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Space between sections */
  margin-top: 100px !important; /* Space from the top to avoid collision with navbar */
  margin-bottom: 100px !important;
  padding: 20px;
  max-width: 60%; /* Adjusted max-width for readability */
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff; /* Set a solid background color to the content area */

  @media (max-width: 1024px) {
    max-width: 80%; /* Expand to 80% for tablet-sized screens */
  }

  @media (max-width: 768px) {
    max-width: 90%; /* Expand to 90% for mobile-sized screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Reduce padding on smaller screens */
  }
`;

// Title of the article with a subtle shadow for better readability
const Title = styled.h1`
  font-size: 28px; /* Adjusted font size for emphasis */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 24px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 20px; /* Further reduce font size for mobile */
  }
`;

// Section Title with larger font size and bold weight
const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 18px; /* Further reduce font size for mobile */
  }
`;

// Paragraph styling with a slightly lighter color for readability
const Paragraph = styled.p`
  font-size: 16px; /* Adjusted font size */
  line-height: 1.6;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// List styling with a custom bullet color
const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;

  li {
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 10px; /* Adjust margin for smaller screens */
  }
`;

// Highlighted section with a semi-transparent background
const Highlight = styled.div`
  background-color: rgba(231, 240, 255, 0.8); /* Semi-transparent light blue */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px; /* Adjusted font size */
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

// Image element styled with fixed dimensions
const StyledImage = styled.img`
  width: 900px; /* Set the desired width */
  height: 500px; /* Set the desired height */
  border-radius: 8px;
  margin: 0 auto; /* Margin around the image */
  display: block;

  @media (max-width: 1024px) {
    width: 100%; /* Adjust image width for tablet screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 768px) {
    width: 100%; /* Adjust image width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 480px) {
    width: 100%; /* Adjust image width for mobile */
    height: auto; /* Maintain aspect ratio */
  }
`;
// Main component for the Abhyanga massage article
const Abhyanga = () => {
  return (
    <ArticleContainer>
      <Title>Co to jest masaż Abhyanga?</Title>
      <StyledImage src={AbhyangaPhoto} alt="Abhyanga massage" />

      <Paragraph>
        W trakcie tego masażu całe ciało jest intensywnie masowane dużą ilością oleju, aby oczyścić organizm z nagromadzonych toksyn. Rytmiczne i głęboko relaksujące ruchy mają za zadanie poruszyć zastałą energię i spowodować swobodny przepływ prany.
      </Paragraph>
      <Paragraph>
        Regularny masaż zapobiega przed negatywnym działaniem stresu, nerwowością, wyczerpaniem oraz przed zaburzeniami, których przyczyną jest Vata w nierównowadze.
      </Paragraph>
      <Paragraph>
        Abhyanga odżywia ciało, przedłuża życie, zapewnia dobry sen, poprawia wygląd skóry, poprawia wzrok i kondycję fizyczną. Jest stosowana przy leczeniu wielu chorób.
      </Paragraph>
      <SectionTitle>Właściwości masażu Abhyanga</SectionTitle>
      <List>
        <li>Działa wyciszająco i regenerująco</li>
        <li>Poprawia wygląd skóry, głęboko ją odżywia</li>
        <li>Modeluje sylwetkę</li>
        <li>Poprawia funkcjonowanie całego organizmu</li>
        <li>Przyspiesza produkcję hormonów w skórze</li>
        <li>Zwiększa ruchomość stawów</li>
        <li>Przyspiesza przemianę materii w komórkach skóry</li>
        <li>Zwiększa siły witalne</li>
        <li>Wzmacnia system immunologiczny, czyniąc organizm bardziej odpornym na różnego rodzaju choroby</li>
        <li>Wzmacnia system nerwowy</li>
      </List>
      <SectionTitle>Wskazania do masażu</SectionTitle>
      <List>
        <li>Niskie ciśnienie</li>
        <li>Stany przewlekłego zmęczenia</li>
        <li>Bezsenność</li>
        <li>Problemy ze wzrokiem</li>
        <li>Stany napięcia i niepokoju</li>
        <li>Nadwaga</li>
        <li>Nadpobudliwość</li>
        <li>Migreny</li>
        <li>Bóle kręgosłupa i stawów</li>
        <li>Szara, zmęczona cera</li>
      </List>
      <SectionTitle>Przeciwwskazania</SectionTitle>
      <List>
        <li>Ostre stany zapalne (niezależnie od umiejscowienia)</li>
        <li>Choroby skóry</li>
        <li>Podwyższona temperatura ciała</li>
        <li>Choroby zakaźne</li>
        <li>Menstruacja</li>
        <li>Choroby serca</li>
        <li>Nadciśnienie</li>
        <li>Ciężarne</li>
      </List>
      <Paragraph>
        Czas trwania masażu: 60 minut
      </Paragraph>
    </ArticleContainer>
  );
};

export default Abhyanga;
