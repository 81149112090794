import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Container for the Prices section
const PricesContainer = styled.div`
  display: flex;
  flex-direction: column; /* Adjusted for vertical stacking */
  align-items: center;
  padding: 20px;
  margin-top: 100px;
    margin-bottom: 200px;

`;

// Table styles
const Table = styled.table`
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 40px;
  border: 4px solid transparent;
  border-image: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
  border-image-slice: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Table header styles
const TableHeader = styled.th`
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
`;

// Table cell styles
const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Header for `Cena` and `Trwanie` columns
const PriceHeader = styled.th`
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
  width: 20%;
`;

// Section header styles
const SectionHeader = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

// Styled Link for the gift message
const GiftMessage = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: white; /* Light background color */
  text-decoration: none; /* Remove default underline from link */
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s; /* Added transition for transform */
    box-shadow: 0 7px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow to indicate clickability */


  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 8px;
    z-index: -1;
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
  }

  &:hover {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow to indicate clickability */
    transform: scale(1.05); /* Slightly enlarge the element on hover */
  }
`;
const Prices = () => {
  return (
    <PricesContainer>
      <SectionHeader>Usługi dla duszy</SectionHeader>
      <Table>
        <thead>
          <tr>
            <TableHeader></TableHeader>
            <PriceHeader>Cena</PriceHeader>
            <PriceHeader>Trwanie</PriceHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>Bezpłatne konsultacje wstępne</TableCell>
            <TableCell style={{ width: '20%' }}>—</TableCell>
            <TableCell style={{ width: '20%' }}>—</TableCell>
          </tr>
          <tr>
            <TableCell>Sesje planu duszy</TableCell>
            <TableCell style={{ width: '20%' }}>350 zł</TableCell>
            <TableCell style={{ width: '20%' }}>3h</TableCell>
          </tr>
          <tr>
            <TableCell>Konchowanie/świecowanie</TableCell>
            <TableCell style={{ width: '20%' }}>50 zł</TableCell>
            <TableCell style={{ width: '20%' }}>30 min</TableCell>
          </tr>
          <tr>
            <TableCell>Access Bars</TableCell>
            <TableCell style={{ width: '20%' }}>200 zł</TableCell>
            <TableCell style={{ width: '20%' }}>1h 30 min</TableCell>
          </tr>
          <tr>
            <TableCell>Sesja Bioterapii/donacja</TableCell>
            <TableCell style={{ width: '20%' }}>—</TableCell>
            <TableCell style={{ width: '20%' }}>—</TableCell>
          </tr>
        </tbody>
      </Table>

      <SectionHeader>Usługi dla ciała</SectionHeader>
      <Table>
        <thead>
          <tr>
            <TableHeader></TableHeader>
            <PriceHeader>Cena</PriceHeader>
            <PriceHeader>Trwanie</PriceHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>Masaż ciała Abhyanga ajurwedyjski</TableCell>
            <TableCell style={{ width: '20%' }}>200 zł</TableCell>
            <TableCell style={{ width: '20%' }}>1h</TableCell>
          </tr>
          <tr>
            <TableCell>Masaż Udvartana detoksykujący</TableCell>
            <TableCell style={{ width: '20%' }}>200 zł</TableCell>
            <TableCell style={{ width: '20%' }}>1h</TableCell>
          </tr>
          <tr>
            <TableCell>Masaż gorącymi kamieniami Ratnaabhyanga</TableCell>
            <TableCell style={{ width: '20%' }}>220 zł</TableCell>
            <TableCell style={{ width: '20%' }}>1h 15 min</TableCell>
          </tr>
          <tr>
            <TableCell>Masaż głowy Shiroabhyanga</TableCell>
            <TableCell style={{ width: '20%' }}>150 zł</TableCell>
            <TableCell style={{ width: '20%' }}>45 min</TableCell>
          </tr>
          <tr>
            <TableCell>Masaż Kobido</TableCell>
            <TableCell style={{ width: '20%' }}>200 zł</TableCell>
            <TableCell style={{ width: '20%' }}>1h</TableCell>
          </tr>
        </tbody>
      </Table>

      <GiftMessage>
        Spraw prezent bliskiej osobie kupując jej bon podarunkowy na dowolne usługi
      </GiftMessage>
    </PricesContainer>
  );
};

export default Prices;
