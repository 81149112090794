import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import BannerImage from '../assets/banner_home.jpg';
import RecommenedImg1 from '../services/massages/photo/kobido.jpg';
import RecommendedImg2 from '../assets/ratnaabhyanga.jpg';
import RecommendedImg3 from '../services/massages/photo/shiroabhyanga.jpg';
import Aboutme from '../assets/aboutme_1.jpg';
import Voucher from '../assets/bonpodarunkowy.jpg';
import Gallery from '../gallery/gallery';

const OutsideContainer = styled.div`
  margin-bottom: 100px;
`;

const BannerContainer = styled.div`
    position: relative;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    color: white;
    padding: 20px;
    box-sizing: border-box;
`;

const BannerImageOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${BannerImage});
    background-size: cover;
    background-position: center;
    filter: brightness(80%);
    z-index: -1;
`;

const BannerText = styled.div`
    max-width: 80%;
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px black;

    @media (min-width: 768px) {
        font-size: 36px;
    }
    
    @media (min-width: 1024px) {
        font-size: 48px;
    }
`;

const Button = styled(Link)` /* Changed to Link */
    display: inline-block;
    margin-top: 20px;
    padding: 15px 30px;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(20, 9, 121, 1) 0%, rgba(8, 22, 133, 1) 9%, rgba(7, 46, 155, 1) 25%, rgba(6, 61, 168, 1) 35%, rgba(4, 93, 196, 1) 56%, rgba(2, 123, 219, 1) 73%, rgba(0, 159, 255, 1) 100%);
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s ease, transform 0.3s ease;
    
    &:hover {
        background: linear-gradient(90deg, rgba(0, 102, 204, 1) 0%, rgba(0, 153, 255, 1) 100%);
        transform: scale(1.05);
    }
`;

const RecommendedServices = styled.div`
    height: 600px;
    background-color: #FFFEFF;
    text-align: center;
    padding: 40px 20px;
    box-sizing: border-box;

    @media (max-width: 930px) {
        height: 900px;
    }
    
    @media (max-width: 610px) {
        height: 750px;
    }
`;

const SectionTitle = styled.div`
    font-size: 12px;
    color: #0056b3;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
`;

const MainTitle = styled.h2`
    font-size: 24px;
    margin-bottom: 40px;
`;

const ServicesContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 70px;
    margin-bottom: 20px;
    flex-wrap: wrap;
`;

const ServiceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
`;

const ServiceCircle = styled.div`
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
`;

const InnerCircle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    height: 95%;
    border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.5);
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
`;

const ArrowButton = styled(Link)` /* Changed to Link */
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 999;
    width: 24px;
    height: 44px;
    transform: translateX(-50%);
    background: #0056b3;
    color: white;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 50%;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #003d80;
    }
`;

const ServiceText = styled.div`
    font-size: 18px;
    color: #333;
`;

const AboutMe = styled.div`
    position: relative;
    background-color: white;
    height: 700px;
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    @media (max-width: 1400px) {
        position: static;
        width: 100%;
        height: auto;
    }
    
    @media (max-width: 610px) {
        margin-top: 550px;
    }
`;

const AboutMeImg = styled.img`
    width: 40%;
    margin-left: 10%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    
    @media (max-width: 1400px) {
        display: none;
    }
`;

const AboutMeContent = styled.div`
    width: 30%;
    padding: 20px;
    height: 40%;
    margin-left: 45%;
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background: white;
    text-align: left;
    
    @media (max-width: 1400px) {
        width: 100%;
        height: auto;
        text-align: center;
        margin-left: 0;
    }
`;

const AboutMeTitle = styled.h3`
    font-size: 24px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        font-size: 38px;
    }
`;

const AboutMeDescription = styled.p`
    font-size: 19px !important;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        font-size: 20px;
    }
`;

const ReadMoreButton = styled(Link)` /* Changed to Link */
    display: inline-block;
    padding: 15px 30px;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(20, 9, 121, 1) 0%, rgba(8, 22, 133, 1) 9%, rgba(7, 46, 155, 1) 25%, rgba(6, 61, 168, 1) 35%, rgba(4, 93, 196, 1) 56%, rgba(2, 123, 219, 1) 73%, rgba(0, 159, 255, 1) 100%);
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
        background: linear-gradient(90deg, rgba(0, 102, 204, 1) 0%, rgba(0, 153, 255, 1) 100%);
        transform: scale(1.05);
    }
`;

const VoucherContainer = styled.div`
    position: relative;
    height: 600px;
    width: 50%;
    border-radius: 30px;
    margin: 0 auto;
    overflow: hidden;
    @media (max-width: 1400px) {
        width: 100%;
    }
`;

const VoucherImage = styled.img`
    width: 100%;
    filter: brightness(80%);
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    @media (max-width: 1400px) {
        width: 100%;
    }
`;

const VoucherButton = styled(Link)` /* Changed to Link */
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-decoration: none;
    padding: 15px 30px;
    width: 60%;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease, transform 0.3s ease;
    text-shadow: 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black;
`;

const Highlight = styled.span`
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 159, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`;

const Home = () => {
  return (
    <OutsideContainer>
      <BannerContainer>
        <BannerImageOverlay />
        <BannerText>
          Odnajdź swój wewnętrzny balans
          <br />
          Poznaj naszą ofertę zabiegów
          <br />
          <Button to="/prices">Moje usługi</Button>
        </BannerText>
      </BannerContainer>
      <RecommendedServices>
        <SectionTitle>Nasi klienci polecają</SectionTitle>
        <MainTitle>Nasze najpopularniejsze zabiegi</MainTitle>
        <ServicesContainer>
          <ServiceWrapper>
            <ServiceCircle image={RecommenedImg1}>
              <InnerCircle />
              <ArrowButton to="/kobido">&gt;</ArrowButton>
            </ServiceCircle>
            <ServiceText>Masaż Kobido</ServiceText>
          </ServiceWrapper>
          <ServiceWrapper>
            <ServiceCircle image={RecommendedImg2}>
              <InnerCircle />
              <ArrowButton to="/ratnaabhyanga">&gt;</ArrowButton>
            </ServiceCircle>
            <ServiceText>Masaż Ratnaabhyanga</ServiceText>
          </ServiceWrapper>
          <ServiceWrapper>
            <ServiceCircle image={RecommendedImg3}>
              <InnerCircle />
              <ArrowButton to="/shiroabhyanga">&gt;</ArrowButton>
            </ServiceCircle>
            <ServiceText>Masaż głowy Shiroabhyanga</ServiceText>
          </ServiceWrapper>
        </ServicesContainer>
      </RecommendedServices>
      <AboutMe>
        <AboutMeImg src={Aboutme} />
        <AboutMeContent>
          <AboutMeTitle>O mnie</AboutMeTitle>
          <AboutMeDescription>
            Specjalizuję się w <Highlight>numerologii</Highlight>, <Highlight>astrologii</Highlight> oraz terapii naturalnych. Jako certyfikowany <Highlight>bioenergoterapeuta</Highlight>, oferuję usługi dbające o Twoje ciało, emocje, umysł i ducha. Znajdziesz tu szeroki zakres terapii i masaży, które pomogą Ci rozwijać swój potencjał. Oferuję również sesje odczytu Planu Duszy, abyś lepiej zrozumiał siebie i swoje cele życiowe.
          </AboutMeDescription>
          <ReadMoreButton to="/about">Czytaj dalej</ReadMoreButton>
        </AboutMeContent>
      </AboutMe>
      <VoucherContainer>
        <VoucherImage src={Voucher} />
        <VoucherButton to="/voucher">Spraw prezent bliskiej osobie – podaruj jej bon podarunkowy na dowolną usługę</VoucherButton>
      </VoucherContainer>
      <Gallery />
    </OutsideContainer>
  );
};

export default Home;
