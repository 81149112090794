import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './components/Home';
import Contact from './components/Contact';
import Prices from './components/Prices';
import Products from './components/Products';
import About from './components/About';
import SoulPlan from './services/SoulPlan';
import Ears from './services/Ears';
import Kobido from './services/massages/Kobido';
import Abhyanga from './services/massages/Abhyanga';
import Shiroabhyanga from './services/massages/Shiroabhyanga';
import Ratnaabhyanga from './services/massages/Ratnaabhyanga';
import Udvartana from './services/massages/Udvartana';
import Voucher from './components/Voucher';
import AccessBars from './services/AccessBars';
import Biotherapy from './services/Biotherapy';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="Content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/sp" element={<SoulPlan />} />
            <Route path="/ears" element={<Ears />} />
            <Route path="/kobido" element={<Kobido />} />
            <Route path="/abhyanga" element={<Abhyanga />} />
            <Route path="/shiroabhyanga" element={<Shiroabhyanga />} />
            <Route path="/ratnaabhyanga" element={<Ratnaabhyanga />} />
            <Route path="/udvartana" element={<Udvartana />} />
            <Route path="/voucher" element={<Voucher />} />
            <Route path="/accessbars" element={<AccessBars />} />
            <Route path="/biotherapy" element={<Biotherapy />} />

          </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
