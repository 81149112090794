import React from 'react'
import styled from 'styled-components';

const ToDo = styled.h1`
font-size: 50px;
margin-top: 100px;
`;

const Products = () => {
  return (
    <ToDo>potrzebuje produktów żeby coś tu dodać</ToDo>
  )
}

export default Products